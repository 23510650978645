import useSticky from "../../hooks/useSticky";
import Menu from "../Header/Menu";
import React from "react";
import './GroupMembers.css';
import gritty from '../../assets/images/gritty.jpg';
// every time you put in a new image you need to to import it here using its own line
// name each one so that the image names match what's being called down below
// use small letters
import fowler from '../../assets/images/Fowler.jpg';
import { Card, Icon, Image } from 'semantic-ui-react'

const ASPECT_RATIO_1 = 2/3;
const ASPECT_RATIO_2 = 4/3;

// Defines a group member's information. Used to make adding group members easier.
// There's probably more information worth adding to each member, but for now this should work just fine.
class GroupMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: props.image,
            name: props.name,
            email: props.email
        }
        if(this.state.image == undefined) {
            this.state.image = gritty;
        }
    }

    render() { // if you want to change the border color on the cards, edit below.
        return (
            <Card classname={'group_member'} border='dark'>
                <Image src={this.state.image}/>
                <Card.Content>
                    <Card.Header>
                        {this.state.name}
                    </Card.Header>
                </Card.Content>
            </Card>
        )
    }
}

// Use the format of group members that are already here to add more group members.
const group = [
    <GroupMember image={fowler} name={'Johanna Fowler 2021'} email={'jfowler@haverford.edu'}/>,
    <GroupMember name={'Gritty'} email={'no@yeah.com'}/>,
  //  <GroupMember image={'/assets/images/gritty.jpg'} name={'Gritty'} email={'no@yeah.com'}/>,
  //  <GroupMember image={'/assets/images/gritty.jpg'} name={'Gritty'} email={'no@yeah.com'}/>,
];

class GroupMembers extends React.Component {

    constructor(props) {
        super(props);
        this.updateDimensions();
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.state = {
            windowWidth: typeof window != 'undefined' ? window.innerWidth : 0,
            windowHeight: typeof window != 'undefined' ? window.innerHeight : 0
        }
        this.setState(this.state);
    }

    getColumns() {
        let aspectRatio = this.state.windowWidth/this.state.windowHeight;
        if(aspectRatio <= ASPECT_RATIO_1) {
            return 1;
        } else if(aspectRatio <= ASPECT_RATIO_2) {
            return 2;
        }
        return 3;
    }

    render() {
        return (
            <main>
                <section className={'groupmembers'}>
                    <div ref={this.props.element}>
                        <h1>
                            Group Members
                        </h1>
                    </div>
                </section>
                <div className={'deckdisplay'}>
                    <Card.Group centered itemsPerRow={this.getColumns()}>
                        {group}
                    </Card.Group>
                </div>
            </main>
        )
    }
}

export default () => {
    const { isSticky, element } = useSticky()
    return (
        <>
            <Menu sticky={isSticky} />
            <GroupMembers element={element} />
        </>
    )
}