import React from 'react';
import { slide as Menu } from "react-burger-menu";
import './Sidebar.css';

class Bar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
          <Menu {...this.props}>
              {this.props.links.map((link) => <a className="menu-item" href={link.location}>{link.name}</a>)}
          </Menu>
        );
    }
}

export default Bar;