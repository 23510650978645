import React from "react";
import Navbar from './Navbar';
import Sidebar from './Sidebar';

// The barrier at which we switch to mobile display on this website. If the width is less than 2/3 the height, then we
// swap. Change this const to modify this barrier.
const ASPECT_RATIO = 2/3;

class Link {
    constructor(name, location) {
        this.name = name;
        this.location = location;
    }
}

// Defines the link structure of the website that is visible to users.
const LINKS = [
    new Link('Home', '/'),
    new Link('Projects', '/projects'),
    new Link('Group Members', '/groupmembers'),
    new Link('Publications', '/publications'),
    new Link('Collaborators', '/collaborators'),
    new Link('Back Page', 'https://sites.google.com/haverford.edu/chlresearchgroup/home')
];

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.updateDimensions();
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.state = {
            windowWidth: typeof window != 'undefined' ? window.innerWidth : 0,
            windowHeight: typeof window != 'undefined' ? window.innerHeight : 0
        }
        this.setState(this.state);
    }

    render() {
        if(this.state.windowWidth / this.state.windowHeight <= ASPECT_RATIO) {
            return <Sidebar sticky={this.props.sticky} links={LINKS}/>
        } else {
            return <Navbar sticky={this.props.sticky} links={LINKS} pageWrapId={"page-wrap"}/>
        }
    }
}
export default Menu;
