import "./Collaborators.css";
import React from 'react';
import useSticky from "../../hooks/useSticky";
import Menu from "../Header/Menu";
import { Item } from 'semantic-ui-react';

class Collaborator extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Item>
                <Item.Content verticalAlign={'middle'}>
                    <Item.Header as='h1'>{this.props.name}</Item.Header>
                    <Item.Description> {this.props.university}</Item.Description>
                    <Item.Extra href={this.props.link}>{this.props.link}</Item.Extra>
                </Item.Content>
            </Item>
        );
    }
}

const COLLABORATOR_LIST = [
    <Collaborator name={"Lou Charkoudian"} university={"Haverford College"} link={""}/>,
    <Collaborator name={"Karin Åkerfeldt"} university={"Haverford College"} link={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}/>,
    <Collaborator name={"Clyde Daly"} university={"Haverford College"} link={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}/>,
    <Collaborator name={"Minhaeng Cho"} university={"Korea University"} link={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}/>,
    <Collaborator name={"David Eliezer"} university={"Weill Cornell Medical College"} link={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}/>,
    <Collaborator name={"Sonia Longhi"} university={"CNRS-Marseille"} link={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}/>,
    <Collaborator name={"Feng Gai"} university={"University of Pennsylvania"} link={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}/>
];

class Collaborators extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <main>
                <section className={'collaborators-header'}>
                    <div ref={this.props.element}>
                        <h1>
                            Collaborators
                        </h1>
                    </div>
                </section>
                <section className={'collaborators'}>
                    <Item.Group relaxed divided>
                        {COLLABORATOR_LIST}
                    </Item.Group>
                </section>
            </main>
        )
    }
}

export default () => {
    const { isSticky, element } = useSticky();
    return (
        <>
            <Menu sticky={isSticky} />
            <Collaborators element={element}/>
        </>
    );
}