import "./Projects.css";
import React from 'react';
import useSticky from "../../hooks/useSticky";
import Menu from "../Header/Menu";
import gritty from '../../assets/images/gritty.jpg';
import { Item } from 'semantic-ui-react';

class Project extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Item>
                <Item.Content verticalAlign={'middle'}>
                    <Item.Header as='a' href={this.props.projectLink}>{this.props.projectName}</Item.Header>
                    <Item.Description>{this.props.people}</Item.Description>
                    <Item.Extra>{this.props.blurb}</Item.Extra>
                </Item.Content>
                <Item.Image src={this.props.imageSource}/>
            </Item>
        );
    }
}

const PROJECT_LIST = [
    <Project projectLink={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}
             projectName={"Ooga Booga"}
             people={"Gritty, Pope Francis"}
             blurb={"I'd like to share a revelation that I've had during my time here. It came to me when I tried to classify your species and I realized that you're not actually mammals. Every mammal on this planet instinctively develops a natural equilibrium with the surrounding environment but you humans do not. You move to an area and you multiply and multiply until every natural resource is consumed and the only way you can survive is to spread to another area. There is another organism on this planet that follows the same pattern. Do you know what it is? A virus. Human beings are a disease, a cancer of this planet. You're a plague and we are the cure."}
             imageSource={gritty}
    />,
];

class Projects extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <main>
                <section className={'projects-header'}>
                    <div ref={this.props.element}>
                        <h1>
                            Projects
                        </h1>
                    </div>
                </section>
                <section className={'projects'}>
                    <Item.Group relaxed divided>
                        {PROJECT_LIST}
                    </Item.Group>
                </section>
            </main>
        )
    }
}

export default () => {
    const { isSticky, element } = useSticky();
    return (
        <>
            <Menu sticky={isSticky} />
            <Projects element={element}/>
        </>
    );
}