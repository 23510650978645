import React from 'react'
import MainPage from './components/MainPage/MainPage'
import GroupMembers from './components/GroupMembers/GroupMembers'
import Publications from './components/Publications/Publications'
import Projects from './components/Projects/Projects'
import Collaborators from './components/Collaborators/Collaborators'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css' // Required so that I can use elements from the semantic-ui library

// The controlling component of the entire React page. A single-page website would be constructed here, but what it does
// since we're using a multi page website is create the link structure and point each link at a different page.
class App extends React.Component {
    constructor(props) {
       super(props);
    }

    render() {
        return(
            <BrowserRouter>
                <div>
                    <Switch>
                        <Route path={'/'} component={MainPage} exact/>
                        <Route path={'/projects'} component={Projects}/>
                        <Route path={'/groupmembers'} component={GroupMembers}/>
                        <Route path={'/publications'} component={Publications}/>
                        <Route path={'/collaborators'} component={Collaborators}/>
                        <Route component={MainPage}/>
                    </Switch>
                </div>
            </BrowserRouter>
        )
    }
}

export default App
