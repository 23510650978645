import React from "react";
import "./Navbar.css";
import Logo from "../../assets/images/caseyface.jpg";

// The links need inline style for some reason - it appears the CSS doesn't work on these list-generated links for some
// odd reason.
const LINK_STYLE = {
    padding: '1rem',
    fontSize: 'fill',
    color: 'white',
};

class Navbar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <nav className={this.props.sticky ? "navbar navbar-sticky" : "navbar"}>
                <div className="navbar--logo-holder">
                    <img src={Logo} alt="logo" className="navbar--logo" />
                    <h1> Web pag e</h1>
                </div>
                <ul className="navbar--link">
                    {this.props.links.map(link => //I had to add inline styles here because the CSS didn't work for some reason
                        <a classname='navbar--link-item' href={link.location} style={LINK_STYLE}>{link.name}</a>)}
                </ul>
            </nav>
        )
    }
}
export default Navbar;
