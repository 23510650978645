import React from 'react';
import './Publications.css';
import useSticky from "../../hooks/useSticky";
import image1 from '../../assets/images/bi-2018-00283c_0004.jpg';
import image2 from '../../assets/images/41467_2019_10184_Fig1_HTML.jpg';
import image3 from '../../assets/images/molecules-24-00405-g003.jpg';
import image4 from '../../assets/images/jz-2018-00969b_0005.gif';
import image5 from '../../assets/images/jp-2017-09641u_0010.gif';
import image6 from '../../assets/images/nihms901916u1.jpg';
import image7 from '../../assets/images/ar-2017-00002b_0008.gif';
import image8 from '../../assets/images/ed-2013-00644a_0007.gif';
import image9 from '../../assets/images/nihms901916u1.jpg';
import image10 from '../../assets/images/ja-2014-05442h_0003.gif';
import image11 from '../../assets/images/jp-2012-11815k_0009.gif';
import image12 from '../../assets/images/15-Figure2-1.png';
import image13 from '../../assets/images/jp-2011-09899m_0008.gif';
import image14 from '../../assets/images/bi-2011-00903p_0007.gif';
import image15 from '../../assets/images/gr1.jpg';
import image16 from '../../assets/images/jp-2010-01447r_0001.jpg';
import image17 from '../../assets/images/jz-2010-000177_0004.gif';
import image18 from '../../assets/images/jp0761158n00001.gif';
import Menu from "../Header/Menu";
import { Item } from 'semantic-ui-react';

// The HTML that displays a single publication (reusable).
class Publication extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Item>
                <Item.Image className='pub-image' size='medium' src={this.props.imageSource} />
                <Item.Content verticalAlign={'middle'}>
                    <Item.Header as='a' href={'https://doi.org/' + this.props.doi}>{this.props.title}</Item.Header>
                    <Item.Description>{this.props.authors}</Item.Description>
                    <Item.Extra href={'https://doi.org/' + this.props.doi}>{'DOI:' + this.props.doi}</Item.Extra>
                </Item.Content>
            </Item>
        );
    }
}

const PUBS_LIST = [
    <Publication imageSource={image2}
                 title={"Tracking carrier protein motions with Raman spectroscopy "}
                 authors={"Samuel C Epstein, Adam R Huff, Emily S Winesett, Casey H Londergan, and Louise K Charkoudian"}
                 doi={'10.1038/s41467-019-10184-2'}/>,
    <Publication imageSource={image3}
                 title={"pKa Determination of a Histidine Residue in a Short Peptide Using Raman Spectroscopy"}
                 authors={"Brett H. Pogostin, Anders Malmendal, Casey H. Londergan, and Karin S. Åkerfeldt"}
                 doi={"10.3390/molecules24030405"}/>,
    <Publication imageSource={image1}
                 title={"Cyanylated Cysteine Reports Site-Specific Changes at Protein–Protein-Binding Interfaces Without " +
                 "Perturbation"}
                 authors={'Shannon R. Dalton, Alice R. Vienneau, Shana R. Burstein, Rosalind J. Xu, Sara Linse, and Casey H. Londergan'}
                 doi={'10.1021/acs.biochem.8b00283'}/>,
    <Publication imageSource={image4}
                 title={"A Direct, Quantitative Connection between Molecular Dynamics Simulations and Vibrational Probe Line Shapes"}
                 authors={"Rosalind J. Xu, Bartosz Blasiak, Minhaeng Cho, Joshua P. Layfield, and Casey H. Londergan"}
                 doi={'10.1021/acs.jpclett.8b00969'}/>,
    <Publication imageSource={image5}
                 title={"The Bend+Libration Combination Band Is an Intrinsic, Collective, and Strongly Solute-Dependent Reporter on the Hydrogen Bonding Network of Liquid Water"}
                 authors={"Pramod Kumar Verma, Achintya Kundu, Matthew S. Puretz, Charvanaa Dhoonmoon, Oriana S. Chegwidden, Casey H. Londergan, and Minhaeng Cho"}
                 doi={"10.1021/acs.jpcb.7b09641"}/>,
    <Publication imageSource={image6}
                 title={"Acyl Carrier Protein Cyanylation Delivers a Ketoacyl Synthase-Carrier Protein Crosslink"}
                 authors={"Grace A. Thiele, Connie P. Friedman, Kathleen J. S. Tsai, Joris Beld, Casey H. Londergan, and Louise K. Charkoudiana"}
                 doi={"10.1021/acs.biochem.7b00219"}/>,
    <Publication imageSource={image7}
                 title={"Vibrational Probes: From Small Molecule Solvatochromism Theory and Experiments to Applications in Complex Systems"}
                 authors={"Bartosz Błasiak, Casey H. Londergan, Lauren J. Webb, and Minhaeng Cho"}
                 doi={"10.1021/acs.accounts.7b00002"}/>,
    <Publication imageSource={image8}
                 title={"NMR Determination of Hydrogen Bond Thermodynamics in a Simple Diamide: A Physical Chemistry Experiment"}
                 authors={"Janine G. Morton, Candice L. Joe, Massiel C. Stolla, Sophia R. Koshland, Casey H. Londergan, and Mark H. Schofield"}
                 doi={"10.1021/ed400644a"}/>,
    <Publication imageSource={image9}
                 title={"Dynamic Asymmetry and the Role of the Conserved Active-Site Thiol in Rabbit Muscle Creatine Kinase"}
                 authors={"Casey H. Londergan, Rachel Baskin, Connor G. Bischak, Kevin W. Hoffman, David M. Snead, and Christopher Reynoso"}
                 doi={"10.1021/bi5008063"}/>,
    <Publication imageSource={image10}
                 title={"Probing the Phosphopantetheine Arm Conformations of Acyl Carrier Proteins Using Vibrational Spectroscopy"}
                 authors={"Matthew N. R. Johnson, Casey H. Londergan, and Louise K. Charkoudian"}
                 doi={"10.1021/ja505442h"}/>,
    <Publication imageSource={image11}
                 title={"A New Raman Spectroscopic Probe of Both the Protonation State and Noncovalent Interactions of Histidine Residues"}
                 authors={"Kevin W. Hoffman, Matthew G. Romei, and Casey H. Londergan*"}
                 doi={"10.1021/jp311815k"}/>,
    <Publication imageSource={image12}
                 title={"Monitoring structural transitions in IDPs by vibrational spectroscopy of cyanylated cysteine "}
                 authors={"Hailiu Yang, Johnny Habchi, Sonia Longhi, Casey H Londergan"}
                 doi={"10.1007/978-1-61779-927-3_17"}/>,
    <Publication imageSource={image13}
                 title={"Covalently Bound Azido Groups Are Very Specific Water Sensors, Even in Hydrogen-Bonding Environments"}
                 authors={"Marta P. Wolfshorndl, Rachel Baskin, Ishita Dhawan, and Casey H. Londergan"}
                 doi={"10.1021/jp209899m"}/>,
    <Publication imageSource={image14}
                 title={"Using Infrared Spectroscopy of Cyanylated Cysteine To Map the Membrane Binding Structure and Orientation of the Hybrid Antimicrobial Peptide CM15"}
                 authors={"Katherine N. Alfieri, Alice R. Vienneau, and Casey H. Londergan"}
                 doi={"10.1021/bi200903p"}/>,
    <Publication imageSource={image15}
                 title={"Probing Structural Transitions in the Intrinsically Disordered C-Terminal Domain of the Measles Virus Nucleoprotein by Vibrational Spectroscopy of Cyanylated Cysteines"}
                 authors={"Connor G. Bischak, Sonia Longhi, David M. Snead, Stéphanie Costanzo, Elodie Terrer, and Casey H. Londergan"}
                 doi={"10.1016/j.bpj.2010.06.060"}/>,
    <Publication imageSource={image16}
                 title={"The effects of alpha-helical structure and cyanylated cysteine on each other"}
                 authors={"Lena Edelstein  1 , Matthew A Stetz, Heather A McMahon, Casey H Londergan"}
                 doi={"10.1021/jp101447r"}/>,
    <Publication imageSource={image17}
                 title={"Cyanylated Cysteine: A Covalently Attached Vibrational Probe of Protein−Lipid Contacts"}
                 authors={"Heather A. McMahon, Katherine N. Alfieri, Katherine A. A. Clark, and Casey H. Londergan"}
                 doi={"10.1021/jz1000177"}/>,
    <Publication imageSource={image18}
                 title={"The CN Stretching Band of Aliphatic Thiocyanate is Sensitive to Solvent Dynamics and Specific Solvation"}
                 authors={"Mark G. Maienschein-Cline and Casey H. Londergan"}
                 doi={"10.1021/jp0761158"}/>,

]

// The entire webpage.
class Publications extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <main>
                <section className={'publication-header'}>
                    <div ref={this.props.element}>
                        <h1>
                            Publications
                        </h1>
                    </div>
                </section>
                <section className={'publications'}>
                    <Item.Group relaxed divided>
                        {PUBS_LIST}
                    </Item.Group>
                </section>
            </main>
        )
    }
}

export default () => {
    const { isSticky, element } = useSticky();
    return (
        <>
            <Menu sticky={isSticky} />
            <Publications element={element}/>
        </>
    );
}